.card {
    border-radius: 12px;
    border: 1.5px solid #dbdfd0;
    background: #fff;
    padding: 36px 31px;
    width: 306px;
    min-width: 240px;
    transition: transform 0.2s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .cardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .cardIcon {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  
  .cardText {
    margin-top: 30px;
    width: 238px;
    max-width: 100%;
  }
  
  .cardTitle {
    color: #2c2f24;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.72px;
    margin: 0;
  }
  
  .cardDescription {
    color: #414536;
    font-size: 16px;
    line-height: 24px;
    margin-top: 15px;
  }
  
  .exploreButton {
    margin-top: 30px;
    color: #ad343e;
    font-weight: 700;
    font-size: 16px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    transition: opacity 0.2s ease;
  }
  
  .exploreButton:hover {
    opacity: 0.8;
  }
  
  @media (max-width: 991px) {
    .card {
      width: 100%;
      padding: 30px 20px;
    }
  }