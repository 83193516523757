.primaryButton {
  border-radius: 118px;
  background-color: rgba(173, 52, 62, 1);
  color: var(--Neutral-01, #fff);
  padding: 20px 32px;
  cursor: pointer;
}

.secondaryButton {
  border-radius: 118px;
  border: 1.5px solid var(--Neutral-07, #2c2f24);
  color: var(--Neutral-07, #182226);
  padding: 20px 32px;
  cursor: pointer;
}

.primaryButton:hover, .secondaryButton:hover{
  opacity: 0.7;
}

@media (max-width: 991px) {
  .primaryButton,
  .secondaryButton {
    padding: 20px 30px;
  }
}
