.navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 29px 0;
    margin: 0 40px;
    font-family: 'DM Sans', sans-serif;
  }
  
  .logo {
    display: flex;
    align-items: center;
    gap: 13px;
    color: #474747;
  }
  
  .logoImage {
    width: 56px;
    height: 56px;
    object-fit: contain;
  }
  
  .logoText {
    font-family: 'Playfair Display', serif;
    font-size: 43px;
    font-weight: 600;
    letter-spacing: -0.4px;
  }
  
  .menuItems {
    display: flex;
    gap: 7px;
    color: #2c2f24;
  }
  
  .menuItem {
    padding: 4px 16px;
    border-radius: 34px;
    font-weight: 500;
    background: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .menuItem:hover,
  .menuItem:focus {
    background-color: #dbdfd0;
  }
  
  .active {
    background-color: #dbdfd0;
  }
  
  .bookButton {
    padding: 12px;
    border-radius: 118px;
    border: 1.5px solid #2c2f24;
    color: #182226;
    font-weight: 700;
    background: none;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .bookButton:hover,
  .bookButton:focus {
    background-color: #2c2f24;
    color: #fff;
  }
  
  @media (max-width: 991px) {
    .navigation {
      flex-direction: column;
      gap: 20px;
      padding: 20px;
    }
  
    .menuItems {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .cartCount {
    position: relative;
    top: -5px;
    right: 15px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: bold;
  }