.infoCard {
    display: flex;
    flex-direction: column;
    color: var(--Neutral-07, #2c2f24);
    justify-content: start;
  }
  
  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
  }
  
  .content {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-top: 24px;
  }
  
  .phoneNumber {
    color: #ad343e;
    font-size: 24px;
    line-height: 1;
    letter-spacing: -0.72px;
    margin-top: 24px;
  }