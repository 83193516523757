.card {
    border-radius: 12px;
    border: 1.5px solid var(--Neutral-03, #dbdfd0);
    background: var(--Neutral-01, #fff);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .cardImage {
    width: 100%;
    aspect-ratio: 1.33;
    object-fit: contain;
  }
  
  .cardContent {
    padding: 24px;
    text-align: center;
  }
  
  .price {
    color: #ad343e;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.72px;
  }
  
  .title {
    color: var(--Neutral-07, #2c2f24);
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    margin: 15px 0;
  }
  
  .description {
    color: var(--Neutral-06, #414536);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .highlight{
    color: var(--Neutral-06, #660a0a);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }