.contactForm {
    background-color: var(--neutral-01);
    border-radius: 16px;
    box-shadow: 0 3px 60px rgba(0, 0, 0, 0.08);
    max-width: 797px;
    margin: -289px auto 0;
    padding: 40px;
    position: relative;
    z-index: 2;
  }
  
  .formContent {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .formRow {
    display: flex;
    gap: 24px;
  }
  
  .inputGroup {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .inputLabel {
    color: var(--neutral-07);
    font: 700 16px 'DM Sans', sans-serif;
  }
  
  .formInput,
  .messageInput {
    border: 1px solid var(--neutral-03);
    border-radius: 72px;
    padding: 0 24px;
    height: 60px;
    font: 400 16px 'DM Sans', sans-serif;
    color: var(--neutral-07);
    background-color: var(--neutral-01);
  }
  
  .messageInput {
    border-radius: 16px;
    height: auto;
    padding: 24px;
    resize: vertical;
    min-height: 156px;
  }
  
  .submitButton {
    background-color: var(--primary);
    color: var(--neutral-01);
    border: none;
    border-radius: 118px;
    padding: 20px 32px;
    font: 700 16px 'DM Sans', sans-serif;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .submitButton:hover {
    background-color: var(--primary-dark);
  }

  @media (max-width: 991px){
    .contactForm {
        margin: -200px 20px 0;
        padding: 20px;
      }
    
      .formRow {
        flex-direction: column;
      }
  }