.page {
    font-family: 'DM Sans', sans-serif;
    width: 100%;
    overflow: hidden;
  }
  
  .menuSection,
  .aboutSection,
  .testimonialsSection {
    padding: 120px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sectionTitle {
    color: #2c2f24;
    font-family: 'Playfair Display', serif;
    font-size: 55px;
    font-weight: 500;
    line-height: 1.1;
    text-align: center;
    margin: 0 0 64px;
  }
  
  .menuGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
    max-width: 1296px;
    width: 100%;
  }
  
  .aboutSection {
    background-color: #f9f9f7;
    flex-direction: row;
    gap: 90px;
    justify-content: center;
  }
  
  .aboutImage {
    width: 649px;
    max-width: 45%;
    aspect-ratio: 1.05;
    object-fit: cover;
    border-radius: 12px;
  }
  
  .aboutContent {
    max-width: 557px;
    flex: 1;
  }
  
  .aboutContent h2 {
    color: #2c2f24;
    font-family: 'Playfair Display', serif;
    font-size: 55px;
    font-weight: 500;
    line-height: 1.1;
    margin: 0 0 24px;
  }
  
  .aboutContent p {
    color: #414536;
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 24px;
  }
  
  .moreButton {
    border: 1.5px solid #2c2f24;
    border-radius: 118px;
    padding: 20px 32px;
    font-size: 16px;
    font-weight: 700;
    color: #182226;
    background: none;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .moreButton:hover {
    background-color: #2c2f24;
    color: #fff;
  }
  
  .testimonialGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
    max-width: 1296px;
    width: 100%;
  }
  
  @media (max-width: 991px) {
    .menuSection,
    .aboutSection,
    .testimonialsSection {
      padding: 60px 20px;
    }
  
    .sectionTitle {
      font-size: 40px;
      margin-bottom: 40px;
    }
  
    .aboutSection {
      flex-direction: column;
      gap: 40px;
    }
  
    .aboutImage {
      max-width: 100%;
    }
  
    .aboutContent h2 {
      font-size: 40px;
    }
  
    .moreButton {
      width: 100%;
      text-align: center;
    }
  }