.heroContainer {
  display: flex;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  z-index: 100;
}

.heroBackground {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.heroContent {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 802px;
  align-items: center;
  justify-content: center;
  padding: 60px;
}

.heroImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.contentWrapper {
  position: relative;
  display: flex;
  margin-bottom: 40px;
  width: 667px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
}

.textContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  color: var(--Neutral-07, #2c2f24);
  font-weight: 300;
  text-align: center;
}

.heroTitle {
  font: 80px/76px Playfair Display, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.heroDescription {
  margin-top: 32px;
  width: 537px;
  font: 20px/32px DM Sans, sans-serif;
}

.buttonGroup {
  display: flex;
  margin-top: 40px;
  align-items: center;
  gap: 16px;
  text-align: right;
  font: 700 16px DM Sans, sans-serif;
}



.primaryButton {
  border-radius: 118px;
  background-color: rgba(173, 52, 62, 1);
  color: var(--Neutral-01, #fff);
  padding: 20px 32px;
}

.secondaryButton {
  border-radius: 118px;
  border: 1.5px solid var(--Neutral-07, #2c2f24);
  color: var(--Neutral-07, #182226);
  padding: 20px 32px;
}

@media (max-width: 991px) {
  .heroBackground {
    max-width: 100%;
  }

  .heroContent {
    max-width: 100%;
    margin: -20px 0;
  }

  .contentWrapper {
    margin-bottom: 10px;
  }

  .heroImage{
    object-fit: fill;
  }

  .heroTitle {
    max-width: 100%;
    font-size: 60px;
  }

  .heroDescription {
    max-width: 100%;
  }
}
