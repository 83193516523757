.footer {
  background-color: white;
  padding: 20px 0 30px;
  color: black;
}

.container {
  margin: 0 auto;
  padding: 0 80px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  gap: 35px;
  margin-bottom: 99px;
}

.brandSection {
  max-width: 293px;
}

.logo {
  display: flex;
  align-items: center;
  gap: 14px;
  text-decoration: none;
  color: black;
  margin-bottom: 30px;
}

.logo img {
  width: 56px;
  height: 56px;
}

.logo span {
  font-family: "Playfair Display", serif;
  font-size: 33px;
  font-weight: 600;
  letter-spacing: -0.4px;
}

.brandSection p {
  color: color;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}

.linksSection {
  display: flex;
  gap: 120px;
}

.pages,
.utility {
  min-width: 149px;
}

h3 {
  color: black;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 40px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 20px;
}

li button {
  padding: 4px 16px;
  border-radius: 34px;
  font-weight: 500;
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

li button:hover {
  color: gray;
}

.facebookSection{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.facebookGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.facebookGrid img {
  width: 100%;
  height: auto;
  object-fit: cover;
  width: 120px;
  height: 120px;
}

.button {
  padding: 4px 16px;
  border-radius: 34px;
  margin-bottom: 20px;
  font-weight: 500;
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}
.button:hover, .button:focus{
  background-color: #dbdfd0;
}

.divider {
  height: 1px;
  background-color: #414536;
  margin-bottom: 30px;
}

.copyright {
  text-align: center;
  color: gray;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
}

@media (max-width: 991px) {
  .footer {
    padding: 60px 0 30px;
  }

  .container {
    padding: 0 20px;
  }

  .content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-bottom: 40px;
  }

  .brandSection {
    max-width: 100%;
  }

  .linksSection {
    flex-direction: column;
    gap: 40px;
  }

  .instagramGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}
