.menuSection {
    padding: 85px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header {
    text-align: center;
    max-width: 557px;
    margin-bottom: 50px;
  }
  
  .title {
    color: var(--Neutral-07, #2c2f24);
    font: 100px/1 Playfair Display, -apple-system, Roboto, Helvetica, sans-serif;
    margin: 0;
  }
  
  .subtitle {
    color: var(--Neutral-06, #495460);
    font: 18px/28px DM Sans, sans-serif;
    margin-top: 20px;
  }
  
  .categories {
    display: flex;
    gap: 15px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .categoryButton {
    border-radius: 50px;
    padding: 12px 24px;
    font: 700 16px DM Sans, sans-serif;
    cursor: pointer;
    border: 1px solid var(--Neutral-03, #dbdfd0);
    background: transparent;
    transition: all 0.2s ease;
  }
  
  .categoryButton.active {
    background-color: rgba(173, 52, 62, 1);
    color: var(--Neutral-01, #fff);
    border-color: rgba(173, 52, 62, 1);
  }
  
  .categoryButton:hover:not(.active) {
    background-color: var(--Neutral-03, #dbdfd0);
  }
  
  .menuGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    width: 100%;
    max-width: 1296px;
  }
  
  @media (max-width: 1200px) {
    .menuGrid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 991px) {
    .menuSection {
      padding: 40px 20px;
    }
  
    .title {
      font-size: 40px;
    }
  
    .menuGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 576px) {
    .menuGrid {
      grid-template-columns: 1fr;
    }
  }