.topBar {
    background-color: #474747;
    padding: 9px 70px;
    display: flex;
    justify-content: center;
  }
  
  .container {
    display: flex;
    width: 100%;
    max-width: 1296px;
    justify-content: space-between;
    align-items: center;
  }
  
  .contactInfo {
    display: flex;
    gap: 25px;
    color: #f9f9f7;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
  }
  
  .contactItem {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .icon {
    width: 18px;
    height: 18px;
    object-fit: contain;
  }
  
  .socialIcons {
    display: flex;
    justify-content: space-between;
    width: 100px;
    gap: 4px;
  }
  
  .socialIcon {
    width: 27px;
    height: 27px;
    object-fit: contain;
  }

  .authButtons {
    display: flex;
    gap: 10px;
  }
  
  .loginButton, .registerButton {
    padding: 4px 16px;
    border-radius: 34px;
    font-weight: 500;
    background: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .loginButton:hover, .registerButton:hover {
    background-color: #dbdfd0;
  }

  .logoutButton{
    padding: 4px 16px;
    border-radius: 34px;
    font-weight: 500;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    background-color: rgb(82, 29, 29);
    transition: background-color 0.3s;
  }
  .logoutButton:hover {
    background-color: #564242;
  }
  
  .rightSide{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 300px;
  }
  
  @media (max-width: 991px) {
    .topBar {
      padding: 20px;
    }
    
    .container {
      flex-direction: column;
      gap: 10px;
    }
    
    .contactInfo {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }