.infoSection {
    background: var(--Neutral-02, #f9f9f7);
    padding: 120px 70px;
    display: flex;
    justify-content: center;
  }
  
  .content {
    max-width: 1296px;
    width: 100%;
    display: flex;
    gap: 40px 122px;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .textContent {
    flex: 1;
    min-width: 240px;
    max-width: 346px;
  }
  
  .title {
    color: var(--Neutral-07, #2c2f24);
    font: 500 55px/61px Playfair Display, -apple-system, Roboto, Helvetica, sans-serif;
    margin: 0;
  }
  
  .description {
    color: var(--Neutral-06, #414536);
    font: 400 16px/24px DM Sans, -apple-system, Roboto, Helvetica, sans-serif;
    margin-top: 20px;
  }

  .appGrid {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .appGrid img {
    max-width: 100%; /* Make image scale to container width */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the image fits without cropping */
    border-radius: 10px; /* Optional: Add rounded corners for better aesthetics */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
  }
  
  .appCard {
    border-radius: 12px;
    border: 1px solid var(--Neutral-02, #f9f9f7);
    background: var(--Neutral-01, #fff);
    box-shadow: 0px 2.979px 59.574px 0px rgba(0, 0, 0, 0.08);
    padding: 33px 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease;
  }
  
  .appCard:hover {
    transform: translateY(-5px);
  }
  
  .appLogo {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
  
  @media (max-width: 991px) {
    .appSection {
      padding: 60px 20px;
    }
  
    .title {
      font-size: 40px;
      line-height: 49px;
    }
  
    .appGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 576px) {
    .appGrid {
      grid-template-columns: 1fr;
    }
  }