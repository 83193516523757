.page {
    width: 100%;
    overflow: hidden;
  }

  .transparentBg{
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .heroSection {
    background-color: #f9f9f7;
    padding: 0 70px 120px;
    display: flex;
    gap: 90px;
    align-items: center;
    justify-content: center;
  }
  
  .heroImage {
    width: 649px;
    min-width: 240px;
    aspect-ratio: 1.05;
    object-fit: contain;
  }
  
  .heroContent {
    max-width: 557px;
    min-width: 240px;
  }
  
  .heroContent h1 {
    font-family: 'Playfair Display', serif;
    font-size: 55px;
    line-height: 61px;
    color: #2c2f24;
    margin-bottom: 24px;
  }
  
  .heroContent p {
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    line-height: 28px;
    color: #2c2f24;
    margin-bottom: 24px;
  }
  
  .heroContent p:last-child {
    font-size: 16px;
    line-height: 24px;
    color: #414536;
  }
  
  .featuresSection {
    background-color: #fff;
  }
  
  .featuresBanner {
    position: relative;
    min-height: 690px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
  }
  
  .bannerImage {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .bannerContent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 596px;
    padding: 0 20px;
  }
  
  .bannerIcon {
    width: 106px;
    height: 106px;
    margin-bottom: 34px;
  }
  
  .bannerContent h2 {
    font-family: 'Playfair Display', serif;
    font-size: 55px;
    line-height: 61px;
    font-weight: 500;
  }
  
  .featuresGrid {
    display: flex;
    justify-content: center;
    gap: 87px;
    flex-wrap: wrap;
    margin-top: 80px;
    padding: 0 70px;
  }
  
  .featureCard {
    width: 294px;
    min-width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .featureIcon {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
  }
  
  .featureContent h3 {
    color: #2c2f24;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 16px;
  }
  
  .featureContent p {
    color: #414536;
    font-size: 16px;
    line-height: 24px;
  }
  
  .testimonialsSection {
    background-color: #fff;
    padding: 117px 80px;
    text-align: center;
  }
  
  .testimonialsSection h2 {
    font-family: 'Playfair Display', serif;
    font-size: 55px;
    line-height: 1.1;
    color: #2c2f24;
    font-weight: 500;
    margin-bottom: 64px;
  }
  
  .testimonialGrid {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  @media (max-width: 991px) {
    .heroSection {
      flex-direction: column;
      padding: 40px 20px;
      gap: 40px;
    }
  
    .heroImage {
      width: 100%;
    }
  
    .heroContent h1 {
      font-size: 40px;
      line-height: 49px;
    }
  
    .featuresBanner {
      min-height: 400px;
    }
  
    .bannerContent h2 {
      font-size: 40px;
      line-height: 49px;
    }
  
    .featuresGrid {
      margin-top: 40px;
      padding: 0 20px;
      gap: 40px;
    }
  
    .testimonialsSection {
      padding: 40px 20px;
    }
  
    .testimonialsSection h2 {
      font-size: 40px;
      margin-bottom: 40px;
    }
  }