.contactInfoContainer {
    align-self: center;
    display: flex;
    margin: 83px;
    align-items: start;
    gap: 40px 90px;
    font-family: DM Sans, sans-serif;
    justify-content: start;
    flex-wrap: wrap;
  }
  
  @media (max-width: 991px) {
    .contactInfoContainer {
      max-width: 100%;
      margin-top: 40px;
    }
  }