.contact {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 100%;
    overflow: hidden;
    background-color: var(--neutral-01);
  }
  
  .background {
    background-color: black;
    background-image: url('.,/../../../assets/img/contact_bg.png');
    background-size: cover;
    background-position: center;
    padding: 0 0 361px;
    position: relative;
  }
  
  .titleSection {
    background-color: rgba(0,0,0,0.6);
    border-radius: 32px;
    text-align: center;
    max-width: 545px;
    margin: 74px auto -72px;
    margin-bottom: 30px;
    padding: 50px;
    position: relative;
    z-index: 1;
  }
  
  .mainTitle {
    color: var(--neutral-01);
    font: 100px/1 'Playfair Display', serif;
    margin: 0;
  }
  
  .subtitle {
    color: var(--neutral-01);
    font: 18px/28px 'DM Sans', sans-serif;
    margin: 24px 0 0;
  }
  
  @media (max-width: 991px) {
    .mainTitle {
      font-size: 40px;
    }
  }
  
  :root {
    --neutral-01: #fff;
    --neutral-02: #f9f9f7;
    --neutral-03: #dbdfd0;
    --neutral-04: #adb29e;
    --neutral-05: #737865;
    --neutral-06: #495460;
    --neutral-07: #2c2f24;
    --primary: #ad343e;
    --primary-dark: #8a2a32;
  }