.testimonialCard {
    border-radius: 12px;
    background-color: #f9f9f7;
    padding: 35px;
    width: 416px;
    min-width: 240px;
    height: 100%;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .title {
    color: #ad343e;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.72px;
    margin: 0 0 24px;
  }
  
  .text {
    color: #414536;
    font-size: 18px;
    line-height: 28px;
    margin: 0;
    flex-grow: 1;
  }
  
  .divider {
    height: 1px;
    background-color: #dbdfd0;
    opacity: 0.7;
    margin: 32px 0;
  }
  
  .author {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .authorImage {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .authorInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .authorName {
    color: #2c2f24;
    font-weight: 700;
    font-size: 16px;
  }
  
  .authorLocation {
    color: #414536;
    font-size: 16px;
  }
  
  @media (max-width: 991px) {
    .testimonialCard {
      width: 100%;
      padding: 20px;
    }
  }